<template>
  <SubmissionScreeningList></SubmissionScreeningList>
</template>

<script>
import SubmissionScreeningList from "@/components/lists/SubmissionScreeningList";
export default {
  name: "TalentSubmissionScreeningPage",
  components: {SubmissionScreeningList}
}
</script>

<style scoped>

</style>
